import {
  AppBar,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import Tabs from "./tabs";
import Logo from "../assets/Roll.png";
import { useTheme } from "@mui/material/styles";
import WalletIcon from "@mui/icons-material/Wallet";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { ethers } from 'ethers';
function Navbar({ setUserAddress, setIsWalletConnected, setProvider }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [connected, setConnected] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState('');
  const [web3, setWeb3] = useState(null);
  const connectToWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = ethers.utils.getAddress(accounts[0]);
        console.log(window.ethereum.net_version)
        const networkId = parseInt(window.ethereum.networkVersion, 10);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider)
        if (networkId !== 137) {
          alert('Please switch to the Matic network.');
          return;
        }
        setIsWalletConnected(true)
        setUserAddress(account);
        setConnected(true);


      } catch (error) {
        console.error(error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  return (
    <StyledAppbar position="static" elevation={0}>
      <StyledContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LogoImage src={Logo} alt="Logo" />
          <Typography
            variant="h3"
            sx={{
              lineHeight: "normal",
              letterSpacing: "0.1563rem",
              fontWeight: "300",
              fontSize: "1.5rem",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            }}
          >
            RollingDoge
          </Typography>
        </Box>
        <Tabs />
        <Stack
          onClick={connectToWallet}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: "0.25rem 0.75rem",
            borderRadius: "0.5rem",
            border: "2px solid #159bd9",
            cursor: "pointer",
            '&:hover .wallet-text': {
              width: connected ? 78.75 : 60.66,
              opacity: 1,
              marginLeft: "0.325rem"
            },
            transition: 'width 0.5s ease-in-out, marginLeft 0.5s ease-in-out, opacity 0.5s ease-in-out',
          }}
        >
          <WalletIcon fontSize="large" sx={{ color: "#159bd7" }} />
          <Typography className="wallet-text" sx={{ width: 0, opacity: 0, marginLeft: "0rem", transition: 'width 0.5s ease-in-out, marginLeft 0.5s ease-in-out, opacity 0.3s ease-in-out' }}>
            {connected ? 'Connected' : 'Connect'}
          </Typography>
        </Stack>
        {/* <ConnectWallet
          className="web3Button-color"
          dropdownPosition={{
            side: "bottom",
            align: "center",
          }}
          detailsBtn={() => {
            return (
              <IconButton sx={{ color: "#159bd7" }}>
                <WalletIcon fontSize="large" />
              </IconButton>
            );
          }}
        /> */}
      </StyledContainer>
    </StyledAppbar>
  );
}

const StyledAppbar = styled(AppBar)(() => ({
  height: "5rem",
  backgroundColor: "#000",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  fontWeight: 600,
  gap: "0.5rem",
  color: "white",
  textTransform: "none",
  marginLeft: "auto",
}));

const StyledContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
});

const LogoImage = styled("img")({
  width: "2.75rem",
  height: "2.5rem",
  marginRight: 16,
  "@media (max-width: 600px)": {
    width: "2.75rem",
    height: "2.5rem",
  },
});

export default Navbar;
