import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
  InputBase,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAddress } from "@thirdweb-dev/react";

import Withdraw from "./withdraw";
import Landing from "./LandingPage/landing";
import ICON_1 from "../assets/icon_1.png";
import ICON_2 from "../assets/icon_2.png";
import ICON_3 from "../assets/icon_3.png";
import ICON_4 from "../assets/icon_4.png";
import ICON_5 from "../assets/icon_5.png";
import ICON_6 from "../assets/icon_6.png";
// import ReferralCards from "./Referals/referralCard"; 
import Web3 from 'web3';
import { rollingDogeContract, rollingDogeROIContract, rollingDogeContractCore, rollingDogeROIContractCore } from '../contracts';
function Main({ dataa, smartContractBalance, totalusers, ownerAddress, userAddress, provider, setTransactionChange, transactionChange, setIsWalletConnected, isWalletConnected }) {
  // console.log(dataa);

  const inputRef = useRef(null);
  const [reffCode, setReffCode] = useState(""); // Initialize with 0 by default
  const location = useLocation();
  const tokenAddress = "0x626e1Ec27B838BCF99DbcEEce73E7495CdF7E384";
  const rollingDogeAddress = "0x628Cd8058629c86A6A9DC67E16461c11C1A9D0F2";

  const address = useAddress();

  const [totalProfit, setTotalProfit] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [smartContractBalancee, setsmartContractBalance] = useState(0);
  const [referralIncome, setReferralIncome] = useState(0);

  const [investedAmount, setInvestedAmount] = useState(0);
  const [refferedBy, setRefferedBy] = useState("");
  const [approveLoading, setApproveLoading] = useState(false)
  const [investLoading, setInvestLoading] = useState(false)
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get("ref");

    if (refParam !== null) {
      setReffCode(refParam);
    } else {
      setReffCode(ownerAddress);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      dataa !== undefined &&
      dataa.referredBy !== "0x0000000000000000000000000000000000000000"
    ) {
      let value = Web3.utils.fromWei(dataa.referralEarning, 'ether');
      // ethers.utils.formatEther(dataa.referralEarning.toString());
      value = parseFloat(value).toFixed(4);
      setReferralIncome(value);

      value = Web3.utils.fromWei(dataa.totalProfit, 'ether');
      // ethers.utils.formatEther(dataa.totalProfit.toString());
      value = parseFloat(value).toFixed(4);
      setTotalProfit(value);

      value = Web3.utils.fromWei(dataa.investedAmount, 'ether');
      // ethers.utils.formatEther(dataa.investedAmount.toString());
      value = parseFloat(value).toFixed(4);
      setInvestedAmount(value);

      setRefferedBy(dataa.referredBy);
    }
  }, [dataa]);

  // function coptReferral() {
  //   const currentDomain = window.location.origin;
  //   const referralLink = `${currentDomain}/?ref=${userAddress}`;
  //   const textarea = document.createElement("textarea");
  //   textarea.value = referralLink;
  //   document.body.appendChild(textarea);
  //   textarea.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(textarea);

  //   alert(`Referral link "${referralLink}" copied to clipboard!`);
  // }

  useEffect(() => {
    if (smartContractBalance !== undefined) {
      setsmartContractBalance(smartContractBalance);
    }
    if (totalusers !== undefined) {
      setTotalUsers(totalusers);
    }
  }, [smartContractBalance, totalusers]);

  const handleApprove = async () => {
    try {
      setApproveLoading(true)
      const approvedAmount = await rollingDogeContract.methods.allowance(userAddress, rollingDogeAddress).call();
      const investAmount = Web3.utils.toWei(inputRef.current.value, 'ether');
      if (approvedAmount >= investAmount) {
        setIsApproved(true);
      } else {
        const signer = await provider.getSigner();
        const transaction = rollingDogeContractCore.connect(signer);
        try {

          const allowance = await transaction.approve(rollingDogeAddress, investAmount);
          await allowance.wait();
          console.log("Receipt: ", allowance)
          setIsApproved(true);
          setApproveLoading(false)
        } catch (error) {
          setApproveLoading(false)
          console.error("Error calling allowance:", error);
        }
      }
    } catch (error) {
      console.error(error);
      setApproveLoading(false)
    }
  }

  const handleInvest = async () => {
    try {
      setInvestLoading(true)
      const signer = await provider.getSigner();
      const transaction = rollingDogeROIContractCore.connect(signer);
      try {
        console.log(reffCode)
        const invest = await transaction.Invest(inputRef.current.value, reffCode);
        await invest.wait();
        console.log("Receipt: ", invest)
        setTransactionChange(!transactionChange)
        setInvestLoading(false)
      } catch (error) {
        console.error("Error calling invest:", error);
        setInvestLoading(false)
      }
    } catch (error) {
      console.error(error);
      setInvestLoading(false)
    }
  }

  return (
    <>
      <Landing />

      <Box
        sx={{
          // minHeight: "9.5625rem",
          // height: "100vh",
          padding: "10%",
          backgroundColor: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          "@media (max-width: 600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            border: "2px solid #159bd7",
            justifyContent: "center",
            width: "90%",
            padding: "5% 10%",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 600px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <Box className="valueBox">
              <img src={ICON_1}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Total Users
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {totalUsers}
              </Typography>
            </Box>
            <Box className="valueBox">
              <img src={ICON_2}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Contract Balance
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {smartContractBalancee} $ROLL
              </Typography>
            </Box>

            <Box className="valueBox">
              <img src={ICON_3}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Total Profit
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {totalProfit} $ROLL
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 600px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <Box className="valueBox">
              <img src={ICON_4}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Referral Earning
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {referralIncome} $ROLL
              </Typography>
            </Box>

            <Box className="valueBox">
              <img src={ICON_5}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Invested Amount
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {investedAmount} $ROLL
              </Typography>
            </Box>
            <Box className="valueBox">
              <img src={ICON_6}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Referred By
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {(refferedBy !== "" && refferedBy !== null && refferedBy !== undefined) ? `${refferedBy.slice(0, 4)}...${refferedBy.slice(-4)}` : "--"}

              </Typography>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          // minHeight: "80vh",
          backgroundColor: "#000",
          padding: "5rem 10rem",
          display: "flex",
          justifyContent: "space-between",
          "@media (max-width: 600px)": {
            padding: "2rem",
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          sx={{
            minHeight: "20rem",
            width: "55%",
            backgroundColor: "#100F0D",
            borderRadius: "20px",
            border: "1px solid #159bd7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            flexWrap: "wrap",
            "@media (max-width: 600px)": {
              padding: "1rem",
              gap: "1rem",
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              color: "#159bd7",
              fontSize: "2.5rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            DEPOSIT
          </Typography>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "35ch" },
              "@media (max-width: 600px)": {
                "& > :not(style)": { m: 1, width: "25ch" },
              },
            }}
            noValidate
            autoComplete="off"
            color={"white"}
          >
            <InputBase
              label="Enter Amount"
              variant="outlined"
              type="number"
              fullWidth
              placeholder="Enter Amount"
              color="warning"
              inputRef={inputRef}
              sx={{
                input: { color: "#000" },
                border: "2px solid #159bd7",
                borderRadius: "10px",
                padding: "10px",
                paddingLeft: "20px",
                backgroundColor: "#FFF",
              }}
            />
          </Box>

          {!isWalletConnected ? (
            <button
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                cursor: 'pointer',
                fontSize: 'inherit',
                color: 'white',
                display: 'inline-block',
              }}
            >
              Connect Wallet First!
            </button>

          ) : (
            <>
              {!isApproved && (
                <Button
                  onClick={handleApprove}
                  className="web3Button-color"
                  disableRipple
                  sx={{ padding: "0.5rem 1rem", minWidth: "6.406rem", minHeight: "2.531rem" }}
                >{approveLoading ?
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{ color: "white", height: `20px !important`, width: `20px !important` }} />
                  </Box> :
                  `Approve`}
                </Button>
              )}
              {isApproved && (
                <Button
                  onClick={handleInvest}
                  className="web3Button-color"
                  disableRipple
                  sx={{ padding: "0.5rem 1rem", minWidth: "6.406rem", minHeight: "2.531rem" }}
                >{investLoading ?
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{ color: "white", height: `20px !important`, width: `20px !important` }} />
                  </Box> :
                  `Invest`}
                </Button>
              )}
            </>
          )}

          {/* {!isApproved && (
            
            <Web3Button
              className="web3Button-color"
              contractAddress={tokenAddress}
              contractAbi={RollingDoge}
              switchNetwork={5}
              action={async (contract) => {
                try {
                  const approvedAmount = await contract.call("allowance", [
                    address,
                    rollingDogeAddress,
                  ]);
                  const investAmount = ethers.utils.parseEther(
                    inputRef.current.value
                  );

                  if (approvedAmount >= investAmount) {
                    setIsApproved(true);
                  } else {
                    await contract.call("approve", [
                      rollingDogeAddress,
                      investAmount,
                    ]);
                    setIsApproved(true);
                  }
                } catch (error) {
                  console.error(error); // HANDLE ERROR HERE SARANG
                }
              }}
            >
              Approve
            </Web3Button>
          )} */}



          {/* {isApproved && (
            <Web3Button
              className="web3Button-color"
              contractAddress="0x628Cd8058629c86A6A9DC67E16461c11C1A9D0F2"
              contractAbi={RollingDogeROI}
              action={async (contract) => {
                console.log(reffCode);
                await contract.call("Invest", [
                  inputRef.current.value,
                  reffCode,
                ]);
              }}
            >
              Invest
            </Web3Button>
          )} */}
        </Box>
        <Withdraw UserData={dataa} provider={provider} setTransactionChange={setTransactionChange} transactionChange={transactionChange} isWalletConnected={isWalletConnected} />
      </Box>

    </>
  );
}


export default Main;