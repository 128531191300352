import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress, // Import CircularProgress for the loading indicator
} from "@mui/material";
import { ethers } from "ethers";
import { web3Provider, rollingDogeROIContract, rollingDogeROIContractCore } from "../contracts";
import { walletConnect } from "@thirdweb-dev/react";
import Web3 from 'web3';

function Withdraw({ UserData, provider, setTransactionChange, transactionChange, isWalletConnected }) {
  const [value, setValue] = useState(10);
  const [profit, setProfit] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // New state for tracking loading status


  useEffect(() => {
    if (UserData !== undefined) {
      getProfit()
    }
  }, [UserData]);

  const getProfit = async () => {
    const signer = await provider.getSigner();
      const transaction = rollingDogeROIContractCore.connect(signer);
      const totalProfit = await transaction.getCurrentProfit();
      const balanceInEther = Web3.utils.fromWei(totalProfit, 'ether');
      setProfit(balanceInEther);
  }

  const handleWithdraw = async () => {
    setIsLoading(true); // Start loading
    if (!rollingDogeROIContract) {
      console.error("RollingDogeROI contract instance is not initialized.");
      setIsLoading(false); // Stop loading
      return;
    }

    const signer = await provider.getSigner();
    const transaction = rollingDogeROIContractCore.connect(signer);
    const totalProfit = await transaction.getCurrentProfit()
    console.log(totalProfit)
    try {
      const withdraw = await transaction.withdraw();
      await withdraw.wait();
      console.log("Receipt: ", withdraw)
      setTransactionChange(!transactionChange)
    } catch (error) {
      console.error("Error calling allowance:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of success or failure
    }
  };

  return (
    <Box
      sx={{
        minHeight: "20rem",
        width: "35%",
        borderRadius: "20px",
        // border: "1px solid #fff",
        backgroundColor: "#100F0D",
        border: "1px solid #159bd7",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        flexWrap: "wrap",
        "@media (max-width: 600px)": {
          minHeight: "5.6875rem",
          width: "100%",
          borderRadius: "1.4875rem",
          padding: "1rem",
          gap: "1rem",
          marginTop: "30px"
        },
      }}
    >
      <Typography
        sx={{
          color: "#159bd7",
          fontSize: "2.5rem",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        }}
      >
        WITHDRAW
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "white",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          letterSpacing: "0.145rem",
        }}
      >
        {profit}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "white",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          letterSpacing: "0.145rem",
        }}
      >
        $ROLL
      </Typography>

      {
        isWalletConnected ? (

          <Button
            onClick={handleWithdraw}
            className="web3Button-color"
            disabled={!window.ethereum || !window.ethereum.selectedAddress}
            disableRipple
            sx={{ padding: "0.5rem 1rem", minWidth: "6.406rem", minHeight: "2.531rem" }}
          >{isLoading ?
            <Box sx={{ display: 'flex' }}>
              <CircularProgress sx={{ color: "white", height: `20px !important`, width: `20px !important` }} />
            </Box> :
            `Withdraw`}
          </Button>

        ) :
          (
            <button
              style={{
                background: 'none',
                border: 'none',
                color: "white",
                padding: 0,
                cursor: 'pointer',
                fontSize: 'inherit',
                display: 'inline-block',
              }}
            >
              Connect Wallet First!
            </button>
          )
      }

    </Box>
  );
}

export default Withdraw;
