import "./styles/Home.css";
import "./App.css";
import Footer from "./Components/footer";
import Main from "./Components/main";
import NavBar from "./Components/navbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom"; // Import Router
import { useEffect, useState } from "react";
import { rollingDogeContract, rollingDogeROIContract, rollingDogeROIContractCore } from './contracts';
import Web3 from 'web3';
import { ethers } from "ethers";
const theme = createTheme();

export default function Home() {

  // const rollingDogeAddress = "0x1eED3F4C32e8dB397471D3049F00d7c7d663A135";
  // const rollingDogeROIAddress = "0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD";
  const rollingDogeAddress = "0x626e1Ec27B838BCF99DbcEEce73E7495CdF7E384"
  const rollingDogeROIAddress = "0x628Cd8058629c86A6A9DC67E16461c11C1A9D0F2"
  const [userProfile, setUserProfile] = useState();
  const [smartContractBalance, setSmartContractBalance] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [userAddress, setUserAddress] = useState("")
  const [ownerAddress, setOwnerAddress] = useState("")
  const [provider, setProvider] = useState()
  const [transactionChange, setTransactionChange] = useState(false)
  const [isWalletConnected, setIsWalletConnected] = useState(false)
  const isValidEthereumAddress = (address) => {
    return Web3.utils.isAddress(address);
  };

  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setUserAddress(accounts[0])
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider)
      // setIsWalletConnected(true)
    } catch (error) {
      console.error("Failed to connect to wallet:", error);
    }
  };

  const getTotalUsers = async () => {
    if (rollingDogeROIContract !== undefined ) {
      const totalUsersCount = await rollingDogeROIContract.methods.totalUsers().call();
      const ownerAddress = await rollingDogeROIContract.methods.owner().call();
      setTotalUsers(totalUsersCount.toString());
      setOwnerAddress(ownerAddress)
      
    }
  };


  const getUserData = async () => {
    if (
      rollingDogeROIContract !== undefined &&
      userAddress !== undefined &&
      isValidEthereumAddress(userAddress)
    ) {
      const userData = await rollingDogeROIContract.methods.Users(userAddress).call();
      setUserProfile(userData);
    } else {
      console.error('Invalid user address');
    }
  };
  // useEffect(() => {
  //   connectWallet()
  // }, [userAddress])
  

  useEffect(()=> {
    getTotalUsers()
  }, [rollingDogeROIContract, transactionChange])

  useEffect(() => {
    getUserData();
  }, [rollingDogeROIContract, userAddress, transactionChange]);



  const getSmartContractBalance = async () => {
    if (rollingDogeContract !== undefined) {
      const balance = await rollingDogeContract.methods.balanceOf(rollingDogeROIAddress).call();
      const balanceInEther = Web3.utils.fromWei(balance, 'ether');
      setSmartContractBalance(balanceInEther)
    
    }
  };

  useEffect(() => {
    getSmartContractBalance();
  }, [transactionChange]);

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <NavBar 
            setUserAddress={setUserAddress}
            isWalletConnected={isWalletConnected} 
            setIsWalletConnected={setIsWalletConnected}
            setProvider={setProvider} 
          />
          <Main
            dataa={userProfile}
            smartContractBalance={smartContractBalance}
            totalusers={totalUsers}
            ownerAddress={ownerAddress}
            userAddress={userAddress}
            provider={provider}
            setTransactionChange = {setTransactionChange}
            transactionChange = {transactionChange}
            isWalletConnected={isWalletConnected} 
            setIsWalletConnected={setIsWalletConnected}
          />
          <Footer />
        </ThemeProvider>
      </Router>
    </div>
  );
}
