// contracts.js
import Web3 from 'web3';
import RollingDoge from "./abis/RollingDoge.json";
import RollingDogeROI from "./abis/RollingDogeROI.json";
import MaticRollingDogeROI from "./abis/constant2.json"
import MaticRollingDoge from "./abis/constant.json"
import TestContractABI from "./abis/test.json"
import { ethers } from 'ethers';
const getWeb3 = () => {
    return new Web3(new Web3.providers.HttpProvider("https://polygon-mainnet.g.alchemy.com/v2/_vJdSIND-eEADxp0aiyd0GHHLjAH7Fu4"));
};

const initContracts = () => {
    const web3 = getWeb3();

    const rollingDogeAddress = "0x626e1Ec27B838BCF99DbcEEce73E7495CdF7E384"  //"0x1eED3F4C32e8dB397471D3049F00d7c7d663A135";
    // const rollingDogeABI = RollingDoge;
    const rollingDogeContract = new web3.eth.Contract(MaticRollingDoge, rollingDogeAddress);
    

    const rollingDogeROIAddress = "0x628Cd8058629c86A6A9DC67E16461c11C1A9D0F2"  //"0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD";
    // const rollingDogeROIABI = RollingDogeROI;
    const rollingDogeROIContract = new web3.eth.Contract(MaticRollingDogeROI, rollingDogeROIAddress);

    
    return { rollingDogeContract, rollingDogeROIContract };
};

const initContractsThroughCore = () => {
    const alchemyProvider = new ethers.providers.AlchemyProvider("matic", "https://polygon-mainnet.g.alchemy.com/v2/_vJdSIND-eEADxp0aiyd0GHHLjAH7Fu4")
    
    const rollingDogeAddress = "0x626e1Ec27B838BCF99DbcEEce73E7495CdF7E384"  //"0x1eED3F4C32e8dB397471D3049F00d7c7d663A135";
    const rollingDogeContractCore = new ethers.Contract(rollingDogeAddress, MaticRollingDoge, alchemyProvider)
    const rollingDogeROIAddress = "0x628Cd8058629c86A6A9DC67E16461c11C1A9D0F2"  //"0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD";
    const rollingDogeROIContractCore = new ethers.Contract(rollingDogeROIAddress, MaticRollingDogeROI, alchemyProvider)
    

    const testContractAddress = "0x159A22b7c6Fd901f6388e01CaDd3e0588dd1fAF6"
    const testContractContractCore = new ethers.Contract(testContractAddress, TestContractABI, alchemyProvider)
    
    return { rollingDogeContractCore, rollingDogeROIContractCore, alchemyProvider, testContractContractCore };
}

export const { rollingDogeContract, rollingDogeROIContract } = initContracts();
export const web3Provider = getWeb3();
export const { rollingDogeContractCore, rollingDogeROIContractCore, alchemyProvider, testContractContractCore } = initContractsThroughCore()





